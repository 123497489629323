import { acceptHMRUpdate, defineStore } from 'pinia'

export const useDoritoJoinModalStore = defineStore('doritoJoinModalStore', {
  state: () => ({
    isDoritoJoinModalVisible: true,
    closeOnMouseOut: false,
  }),
  actions: {
    showDoritoJoinModal() {
      this.isDoritoJoinModalVisible = true
    },
    hideDoritoJoinModal() {
      this.isDoritoJoinModalVisible = false
    },
    setFlagCloseOnMouseOut(flag: boolean) {
      this.closeOnMouseOut = flag
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDoritoJoinModalStore, import.meta.hot))
}
